.fullscreen-menu-wrapper .fullscreen-menu-col {
    h1, h2, h3, h4, h5, h6 {
        @include font-size($fullscreen-menu-header-font-size);
        font-weight: $fullscreen-menu-header-font-weight;
        color: $fullscreen-menu-header-color;
        text-transform: $fullscreen-menu-header-text-transform;
    }
}

.fullscreen-menu-wrapper .fullscreen-menu-col ul li:before {
    --custom-ul-svg-color: #{$fullscreen-menu-list-icon-color}
}

//
// Additional blocks
//
.fullscreen-menu-wrapper .ccm-block-social-links a {
    color: inherit;
    font-size: rfs-value(24px);
}

.fullscreen-menu-wrapper .ccm-block-social-links ul.list-inline li {
    margin-right: 20px;
}

.fullscreen-menu-wrapper .ccm-block-social-links a {
    color: $fullscreen-menu-nav-link-color;
}

.fullscreen-menu-wrapper .ccm-block-social-links a:hover {
    color: $fullscreen-menu-primary;
}

.ccm-page .fullscreen-menu-wrapper .switch-language .switch-language-current,
.ccm-page .fullscreen-menu-wrapper .switch-language .switch-language-current:hover,
.ccm-page .fullscreen-menu-wrapper .switch-language.is-open .switch-language-current {
    background: $fullscreen-menu-bg;
    color: $fullscreen-menu-color;
    border: 1px solid #{rgba($fullscreen-menu-color, .2)};
}

.fullscreen-menu-wrapper .switch-language .switch-language-option  {
    background: $fullscreen-menu-bg;
    color: $fullscreen-menu-color;
    border: 1px solid #{rgba($fullscreen-menu-color, .2)};
    border-top: 0;
}

;@import "sass-embedded-legacy-load-done:2227";