.email-wrapper a,
.website-wrapper a,
.phone-wrapper a {
    color: $body-color;
    text-decoration: none;
}

.email-wrapper a:hover,
.website-wrapper a:hover,
.phone-wrapper a:hover {
    color: $primary;
}

.email-wrapper a:before,
.website-wrapper a:before,
.phone-wrapper a:before,
.bus-wrapper:before,
.link-wrapper:before,
.link-new-window-wrapper:before {
    font-family: $font-awesome;
    margin-right: rfs-value(7px);
    font-weight: 900;
    position: relative;
}

.phone-wrapper a:before {
    content: '\f879';
}

.email-wrapper a:before {
    content: '\f0e0';
}

.website-wrapper a:before {
    content: '\f7a2';
}

.bus-wrapper:before {
    content: '\f207 ';
}

.link-wrapper:before {
    content: '\f061';
}

.link-new-window-wrapper:before {
    content: '\f35d';
    font-size: 90%;
    top: -1px;
}

;@import "sass-embedded-legacy-load-done:2191";