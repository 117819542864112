// Set padding-top to 0 for the first section
.section-pt-0 [class*=ccm-custom-style-section] {
    padding-top: 0 !important;
}
        h1, h2 {
            font-family: $font-family-secondary !important;
        }

        h2 {
            letter-spacing: -1px;
        }

        h1 {
            margin-bottom: 1rem !important;
            margin-left: -5px;
            letter-spacing: -3px;

        }

        h3, h4, h5 {
            font-family: $font-family-base !Important;
        }

        p {
            line-height: 1.75rem;
        }

        .line-before-after {
            text-align: Center;
            margin: 0 auto;
            max-width: 500px;
            margin-bottom: 4rem;

            h3 {
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: rfs-value(24px);
                    height: 2px;
                    background: $body-color;
                    position: absolute;
                    top: 50%;
                    left: -10px;
                }

                &:after {
                    content: "";
                    display: block;
                    width: rfs-value(24px);
                    height: 2px;
                    background: $body-color;
                    position: absolute;
                    top: 50%;
                    right: -10px;
                }
            }
        }

        .gg-item-card-text-title {
            color: $white !important;
            letter-spacing: $font-letter-spacing-sm;
        }

        .blog-date {
            p.ccm-block-page-attribute-display-wrapper {
                margin-left: 1rem;
                color: $gray-500;

                &:before {
                    display: inline-block;
                    margin-right: rfs-value(4px);
                    content: "\f073";
                    font-family: "Font Awesome 5 Free";
                    color: $gray-500;
                }
            }
        }

    // Common section styles
.weiss-padding,
.grau-100-padding,
.grau-200-padding,
.grau-300-padding,
.akzentfarbe-1-padding,
.akzentfarbe-2-padding,
.akzentfarbe-3-padding,
.akzentfarbe-4-padding,
.akzentfarbe-5-padding,
.rahmenlinie-unten-padding,
.parallax {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
    position: relative;

    @include media-breakpoint-up(xl) {
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 4;
    }

    @include media-breakpoint-up(xxl) {
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 4;
    }
}

.weiss, .weiss-padding, .rahmenlinie-unten-padding, .mit-icon {
    background-color: $white;
}

.grau-100, .grau-100-padding {
    background-color: $gray-100;
}

.grau-200, .grau-200-padding {
    background-color: $gray-200;
}

.grau-300, .grau-300-padding {
    background-color: $gray-300;
}

.akzentfarbe-1, .akzentfarbe-1-padding {
    background-color: $primary;
}

.akzentfarbe-2, .akzentfarbe-2-padding {
    background-color: $secondary;
}

.akzentfarbe-3, .akzentfarbe-3-padding {
    background-color: $tertiary;
}

.aktzenfarbe-4, .akzentfarbe-4-padding {
    background-color: $quaternary;
}

.akzentfarbe-5, .akzentfarbe-5-padding {
    background-color: $quinary;
}

// Sections content
.akzentfarbe-1, .akzentfarbe-1-padding,
.akzentfarbe-2, .akzentfarbe-2-padding,
.akzentfarbe-3, .akzentfarbe-3-padding,
.akzentfarbe-4, .akzentfarbe-4-padding,
.akzentfarbe-5, .akzentfarbe-5-padding {
    --custom-ul-svg-color: #fff;

    color: #ffffff;
    letter-spacing: 0.3px;

    h1, h2, h3, h4, h5, h6, a, .small {
        color: #ffffff !important;
    }

    a {
        color: #ffffff !important;

        &:hover, &:focus {
            color: #ffffff !important;
        }
    }

    b, strong {
        color: #ffffff !important;
    }

    .blockquote,
    blockquote {
        background: #fff !important;
        color: $body-color !important;

        a {
            color: $body-color !important;
        }
    }

    mark,
    .mark {
        color: $body-color !important;
        background: #fff !important;
    }
}

.rahmenlinie-unten,
.rahmenlinie-unten-padding {
    border-bottom: 1px solid $border-color;
    position: relative;
}

;@import "sass-embedded-legacy-load-done:2192";