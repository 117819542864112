$ajs-breakpoint: 768px !default;
$ajs-transition-property: all !default;
$ajs-transition-duration: 0.7s !default;
$ajs-transition-delay: 0s !default;
$ajs-transition-timing-function: ease !default;
$ajs-distance: 200px !default;

:root {
    --ajs-transition-property: #{$ajs-transition-property};
    --ajs-transition-duration: #{$ajs-transition-duration};
    --ajs-transition-delay: #{$ajs-transition-delay};
    --ajs-transition-timing-function: #{$ajs-transition-timing-function};
    --ajs-distance: #{$ajs-distance};
}

html:not(.ccm-edit-mode) [class*="ajs-"] {
    @media (min-width: $ajs-breakpoint) {
        transition-property: var(--ajs-transition-property);
        transition-duration: var(--ajs-transition-duration);
        transition-delay: var(--ajs-transition-delay);
        transition-timing-function: var(--ajs-transition-timing-function);
        opacity: 0;
        pointer-events: none;
    }
}

html:not(.ccm-edit-mode) .ajs-animate {
    @media (min-width: $ajs-breakpoint) {
        opacity: 1;
        pointer-events: auto;
    }
}

html:not(.ccm-edit-mode) [class*="ajs-zoom"] {
    --ajs-distance: 100px;
    --ajs-transition-property: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

html:not(.ccm-edit-mode) [class*="ajs-flip"] {
    --ajs-transition-property: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

html:not(.ccm-edit-mode) [class*="ajs-fade"] {
    --ajs-distance: 200px;
    --ajs-transition-property: all, opacity;
    --ajs-transition-duration: 0.7s, 1s;
}

// Slide / Fade
html:not(.ccm-edit-mode) .ajs-slide-left,
html:not(.ccm-edit-mode) .ajs-fade-left {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(var(--ajs-distance), 0, 0);
    }
}

html:not(.ccm-edit-mode) .ajs-slide-right,
html:not(.ccm-edit-mode) .ajs-fade-right {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(calc(var(--ajs-distance) * -1), 0, 0);
    }
}

html:not(.ccm-edit-mode) .ajs-slide-up,
html:not(.ccm-edit-mode) .ajs-fade-up {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, var(--ajs-distance), 0);
    }
}

html:not(.ccm-edit-mode) .ajs-slide-down,
html:not(.ccm-edit-mode) .ajs-fade-down {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, calc(var(--ajs-distance) * -1), 0);
    }
}

html:not(.ccm-edit-mode) .ajs-slide-up-left,
html:not(.ccm-edit-mode) .ajs-fade-up-left {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(var(--ajs-distance), var(--ajs-distance), 0);
    }
}

html:not(.ccm-edit-mode) .ajs-slide-up-right,
html:not(.ccm-edit-mode) .ajs-fade-up-right {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(
                calc(var(--ajs-distance) * -1),
                var(--ajs-distance),
                0
        );
    }
}

html:not(.ccm-edit-mode) .ajs-slide-down-left,
html:not(.ccm-edit-mode) .ajs-fade-down-left {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(
                var(--ajs-distance),
                calc(var(--ajs-distance) * -1),
                0
        );
    }
}

html:not(.ccm-edit-mode) .ajs-slide-down-right,
html:not(.ccm-edit-mode) .ajs-fade-down-right {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(
                calc(var(--ajs-distance) * -1),
                calc(var(--ajs-distance) * -1),
                0
        );
    }
}

// Zoom
html:not(.ccm-edit-mode) .ajs-zoom-in {
    @media (min-width: $ajs-breakpoint) {
        transform: scale(0.6);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-in-left {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(var(--ajs-distance), 0, 0) scale(0.6);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-in-right {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(calc(var(--ajs-distance) * -1), 0, 0) scale(0.6);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-in-up {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, var(--ajs-distance), 0) scale(0.6);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-in-down {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, calc(var(--ajs-distance) * -1), 0) scale(0.6);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-out {
    @media (min-width: $ajs-breakpoint) {
        transform: scale(1.2);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-out-left {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(var(--ajs-distance), 0, 0) scale(1.2);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-out-right {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(calc(var(--ajs-distance) * -1), 0, 0) scale(1.2);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-out-up {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, var(--ajs-distance), 0) scale(1.2);
    }
}

html:not(.ccm-edit-mode) .ajs-zoom-out-down {
    @media (min-width: $ajs-breakpoint) {
        transform: translate3d(0, calc(var(--ajs-distance) * -1), 0) scale(1.2);
    }
}

html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-up,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-down,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-up-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-up-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-down-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-slide-down-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-up,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-down,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-up-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-up-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-down-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-fade-down-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-in,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-in-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-in-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-in-up,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-in-down,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-out,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-out-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-out-right,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-out-up,
html:not(.ccm-edit-mode) .ajs-animate.ajs-zoom-out-down {
    @media (min-width: $ajs-breakpoint) {
        transform: translateZ(0) scale(1);
    }
}

// Flip
html:not(.ccm-edit-mode) .ajs-flip-left {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateY(-100deg);
    }
}

html:not(.ccm-edit-mode) .ajs-flip-right {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateY(100deg);
    }
}

html:not(.ccm-edit-mode) .ajs-animate.ajs-flip-left,
html:not(.ccm-edit-mode) .ajs-animate.ajs-flip-right {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateY(0);
    }
}

html:not(.ccm-edit-mode) .ajs-flip-up {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateX(-100deg);
    }
}

html:not(.ccm-edit-mode) .ajs-flip-down {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateX(100deg);
    }
}

html:not(.ccm-edit-mode) .ajs-animate.ajs-flip-up,
html:not(.ccm-edit-mode) .ajs-animate.ajs-flip-down {
    @media (min-width: $ajs-breakpoint) {
        transform: perspective(2500px) rotateX(0);
    }
}

;@import "sass-embedded-legacy-load-done:2196";