.ccm-page .custom-hover-overlay {
    transition: $transition-base;
    z-index: 2;
    background: $custom-hover-overlay-bg;
    opacity: 0;
}

.ccm-page .custom-hover:hover .custom-hover-overlay {
    opacity: $custom-hover-overlay-bg-opacity;
}

.custom-hover .custom-hover-icon-container,
.custom-hover .custom-hover-element-container {
    z-index: 3;
    font-size: rfs-value(31px);
    color: $custom-hover-overlay-icon-color;
    opacity: 0;
    transition: $transition-base;
}

.custom-hover .custom-hover-element-container {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-shadow: 1px 1px 1px rgba(#000, 0.5);
    @include font-size(20px);
}

.custom-hover:hover .custom-hover-icon-container,
.custom-hover:hover .custom-hover-element-container {
    opacity: 1;
}

.ccm-page .custom-hover-fit-content {
    width: fit-content;
}

;@import "sass-embedded-legacy-load-done:2195";