.hw-back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
  text-decoration: none;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  background: $gray-700;
}

.hw-back-to-top:hover,
.hw-back-to-top:focus {
  background: red !important;
}

.hw-back-to-top i {
  color: #FFFFFF;
  font-size: 25px;
  position: static !important;

}
.hw-back-to-top:hover i{
  bottom: 5px;
}

;@import "sass-embedded-legacy-load-done:2222";