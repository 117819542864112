.header-top .ccm-block-social-links a {
    color: $header-social-link-color;
}

.header-top .ccm-block-social-links a:hover {
    color: $header-social-link-hover-color;
}

.scrolled .header-top .ccm-block-social-links a {
    color: $header-social-link-scrolled-color;
}

.scrolled .header-top .ccm-block-social-links a:hover {
    color: $header-social-link-scrolled-hover-color;
}

.header-top .ccm-block-social-links a i {
    font-size: rfs-value(24px);
}

;@import "sass-embedded-legacy-load-done:2221";