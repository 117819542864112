.footer {
    background-color: $footer-bg;
    color: $footer-color;

    --custom-ul-svg-color: $footer-color;
    }

.footer p {
    margin-bottom: rfs-value(10px);
}

.footer h4 {
    font-size: 1.75rem;
    line-height: 1.25;
    font-family: $font-family-secondary;
    margin-bottom: 0.5rem;
}

.footer .phone-wrapper a,
.footer .email-wrapper a,
.footer a {
    color: $footer-color !important;
    padding: 0;
    text-decoration: none;

    &:hover, &:focus, &:active {
        color: $footer-hover-color !important;
        background-color: transparent !important;
    }
}

.footer hr {
    margin: ($spacer * 1.5) 0;
}

.footer i.fab {
    font-size: 2rem;
    margin-right: .25rem;
}

.footer-bottom {
    border-top: 1px solid rgba(#fff, .25);
    font-size: rfs-value(12px);
    letter-spacing: $font-letter-spacing-sm;
    color: rgba(255,255,255,0.6);
}

.footer-bottom a {
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $white;
    }
}

;@import "sass-embedded-legacy-load-done:2203";