.ccm-page .page-list-grid-no-pages,
.ccm-page .page-list-grid-wrapper {
    margin-bottom: rfs-value(40px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rfs-value(50px);
    }
}

.page-list-grid-wrapper .ccm-pagination-wrapper {
    margin-top: rfs-value(30px);

    @include media-breakpoint-up(md) {
        margin-top: rfs-value(50px);
        display: flex;
        justify-content: center;
    }
}

.ccm-page .page-list-grid {
    @include media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 30px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.ccm-page .page-list-grid-item {
    @include rfs(40px, margin-bottom);
    @include rfs(40px, padding-bottom);

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0 !important;
    }
}

.ccm-page .page-list-grid-item-title a {
    transition: $transition-base;
    color: inherit;
}

.ccm-page .page-list-grid-item-title a:hover {
    color: $primary;
}

.ccm-page .page-list-grid-item-date,
.date {
    color: $gray-500;
    font-size: $font-size-sm;
    &:before {
        display: inline-block;
        margin-right: rfs-value(4px);
        content: "\f073";
        font-family: "Font Awesome 5 Free";
        color: $gray-400;
    }
}

.ccm-page .page-list-grid-item-read-more .fa-angle-right {
    margin-left: rfs-value(8px);
}

.page-list-grid-item.with-thumbnail {
    @include media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
        @include rfs(0 40px, grid-gap);
        grid-template-areas:
            'page-list-grid-item-thumbnail'
            'page-list-grid-item-title'
            'page-list-grid-item-date'
            'page-list-grid-item-description'
            'page-list-grid-item-read-more';
    }
}

.with-thumbnail .page-list-grid-item-thumbnail-container {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-grid-item-thumbnail;
    }
}

.page-list-grid-item-title {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-grid-item-title;
    }
}

.page-list-grid-item-date {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-grid-item-date;
    }
}

.page-list-grid-item-description {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-grid-item-description;
    }
}

.page-list-grid-item-read-more-container {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-grid-item-read-more;
    }
}

;@import "sass-embedded-legacy-load-done:2214";