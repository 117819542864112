.ccm-page a {
    transition: $transition-base;
}

.ccm-page a[href^="tel:"] {
    text-decoration: none !important;
}

.ccm-page b,
.ccm-page strong {
    font-weight: $font-weight-bold;
}

::selection {
    background: $primary;
    color: $white;
}

.mark {
    color: $mark-color;
}

.blockquote,
blockquote {
    padding: rfs-value(30px);
    background: $gray-100;
    margin: $blockquote-margin-y 0;
    @include font-size($blockquote-font-size);
    position: relative;

    @include media-breakpoint-up(md) {
        padding: rfs-value(40px 90px 40px 90px);
    }
}

blockquote > :last-child {
    margin-bottom: 0;
}

blockquote:before,
.blockquote:before {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f10d';
    display: block;
    font-size: rfs-value(33px);
    color: $gray-400;
    margin-bottom: rfs-value(15px);
    height: 1em;

    @include media-breakpoint-up(md) {
        position: absolute;
        left: rfs-value(33px);
        top: rfs-value(30px);
        margin-bottom: 0;
    }
}

.ccm-page ul.custom-ul {
    @extend %custom-ul;
}

.ccm-page ul.custom-ul-arrow {
    @extend %custom-ul-arrow;
}

.ccm-page ul.custom-ul-dot {
    @extend %custom-ul-dot;
}

.ccm-page ul.custom-ul-check {
    @extend %custom-ul-check;
}

.ccm-page ul.custom-ul-dash {
    @extend %custom-ul-dash;
}

.ccm-page ol.custom-ol {
    @extend %custom-ol;
}

;@import "sass-embedded-legacy-load-done:2190";