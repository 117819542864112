.ccm-block-social-links {

}

.ccm-block-social-links a {
    color: $body-color;
}

.ccm-block-social-links a:hover {
    color: $primary;
}

;@import "sass-embedded-legacy-load-done:2212";