@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../fonts/OpenSans/open-sans-regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../../fonts/OpenSans/open-sans-bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Wallpoet';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../fonts/Wallpoet/wallpoet-regular-webfont.woff2) format('woff2');
}

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(../../fonts/Roboto/roboto.woff2) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(../../fonts/Roboto/roboto-italic.woff2) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(../../fonts/Roboto/roboto-bold.woff2) format('woff2');
    }

;@import "sass-embedded-legacy-load-done:2189";