.ccm-block-custom-template-date-time {
  margin-top: -1rem;
  margin-left: 1rem;
  color: #aaa;
  p.ccm-block-page-attribute-display-wrapper:before {
    color: #aaa;
    content: "\f073";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    margin-right: .5rem;
  }
}

;@import "sass-embedded-legacy-load-done:2223";