.ccm-page .btn {
    --bs-btn-padding-x: #{rfs-value(24px)};
    --bs-btn-padding-y: #{rfs-value(6px)};
    --bs-btn-font-family: #{$font-family-base};
    --bs-btn-font-size: #{rfs-value(16px)};
    --bs-btn-line-height: #{rfs-value(20px)};
    --bs-btn-font-weight: #{$font-weight-normal};
    --bs-btn-border-radius: 0;
    --bs-btn-border-width: 2px;
}

.ccm-page .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$primary};
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{lighten($primary, 10%)};
    --bs-btn-hover-border-color: #{lighten($primary, 10%)};
    --bs-btn-focus-shadow-rgb: #{to-rgb(mix($primary, $primary, 15%))};
    --bs-btn-active-color:  #fff;
    --bs-btn-active-bg: #{lighten($primary, 10%)};
    --bs-btn-active-border-color: #{lighten($primary, 10%)};
    --bs-btn-active-shadow: #{$btn-active-box-shadow};
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$primary};
    --bs-btn-disabled-border-color: #{$primary};
}

.ccm-page .btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{lighten($secondary, 10%)};
    --bs-btn-hover-border-color: #{lighten($secondary, 10%)};
    --bs-btn-focus-shadow-rgb: #{to-rgb(mix($secondary, $secondary, 15%))};
    --bs-btn-active-color:  #fff;
    --bs-btn-active-bg: #{lighten($secondary, 10%)};
    --bs-btn-active-border-color: #{lighten($secondary, 10%)};
    --bs-btn-active-shadow: #{$btn-active-box-shadow};
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$secondary};
    --bs-btn-disabled-border-color: #{$secondary};
}

.ccm-page .btn-tertiary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$tertiary};
    --bs-btn-border-color: #{$tertiary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{lighten($tertiary, 10%)};
    --bs-btn-hover-border-color: #{lighten($tertiary, 10%)};
    --bs-btn-focus-shadow-rgb: #{to-rgb(mix($tertiary, $tertiary, 15%))};
    --bs-btn-active-color:  #fff;
    --bs-btn-active-bg: #{lighten($tertiary, 10%)};
    --bs-btn-active-border-color: #{lighten($tertiary, 10%)};
    --bs-btn-active-shadow: #{$btn-active-box-shadow};
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$tertiary};
    --bs-btn-disabled-border-color: #{$tertiary};
}

;@import "sass-embedded-legacy-load-done:2209";