.sitemap-tree-container {
    @include media-breakpoint-up(md) {
        min-height: 40vh;
    }
}

.sitemap-tree-container > .fa-sitemap {
    color: $gray-100;
    z-index: -1;
    @include rfs(500px);
}

.ccm-page .sitemap-tree li {
    border-left: 1px dotted $gray-500;
    margin-left: 11px;
}

.ccm-page .sitemap-tree li a:not(:hover) {
    color: $body-color;
}

.ccm-page .sitemap-tree li a {
    margin-left: 32px;
    margin-bottom: 5px;
    margin-top: 5px;
    transition: $transition-base;
}

.ccm-page .sitemap-tree li a:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -32px;
    width: 22px;
    border-top: 1px dotted $gray-500;
    height: 1px;
    margin: auto;
}

// Home
.ccm-page .sitemap-tree li.nav-home {
    border-left: 0;
    padding-left: 0;
    margin-left: 3px;
}

.ccm-page .sitemap-tree li.nav-home a {
    margin-left: 10px;
}

.ccm-page .sitemap-tree li.nav-home a:before {
    display: none;
}

;@import "sass-embedded-legacy-load-done:2218";