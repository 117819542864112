.hamburger-area {
    padding: 0.5rem;
    background-color: $black;
}
.scrolled .hamburger-area {
    background: transparent;
}
.scrolled .hamburger-inner,
.scrolled .hamburger-inner::before,
.scrolled .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

.scrolled .hamburger.is-active .hamburger-inner,
.scrolled .hamburger.is-active .hamburger-inner::before,
.scrolled .hamburger.is-active .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

;@import "sass-embedded-legacy-load-done:2197";