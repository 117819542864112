.ccm-page {
    overflow-x: hidden;
}

.container-fluid,
.container {
    //padding-left: rfs-value(30px);
    //padding-right: rfs-value(30px);
}

;@import "sass-embedded-legacy-load-done:2201";