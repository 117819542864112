.hero,
.hero-content {
    height: $hero-height;
}

.ccm-toolbar-visible .hero  {
    top: 48px !important;
}

.hero {
    background: $gray-300;
}

.hero-content {

}

.hero-text {
    .h1, .h2, .h3, .h4,
    h1, h2, h3, h4, p {
        color: #fff;
        text-shadow: -1px 1px 1px #000;
    }
}

.hero-content h1 {
    margin-bottom: rfs-value(20px);
    font-weight: $font-weight-semibold;
    font-size: rfs-value(44px);

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(90px);
    }
}

.hero-content h4 {
    font-family: $font-family-secondary;

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(40px);
    }
}

.hero-content p {
    font-size: rfs-value(18px);

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(24px);
    }
}

.hero-scroll-down {
    position: absolute;
    z-index: 60;
    bottom: 2vh;
    width: 100%;
    text-align: center;
    height: 100px;
}

.hero-scroll-down-button {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: rfs-value(48px);
    color: rgba(#fff, 0.7);
    outline: 0;
    animation: up-down 2s infinite linear;
}

;@import "sass-embedded-legacy-load-done:2204";