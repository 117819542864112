// Unordered list
%custom-ul {
    padding-left: 0.9rem;
}

%custom-ul ul,
%custom-ul ol {
    margin-top: 5px;
    padding-left: 0.9rem;
}

%custom-ul li {
    margin-bottom: 5px;
}

%custom-ul > li {
    list-style: none;
    position: relative;
    padding-left: 1.2rem;
}

%custom-ul li:last-child {
    margin-bottom: 0;
}

%custom-ul > li:before {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f111';
    position: absolute;
    height: 1em;
    color: var(--custom-ul-svg-color, var(--bs-primary));
    top: 2.4em;
    left: 0.8em;
    font-size: 0.24em;

    @include media-breakpoint-up(lg) {
        top: 2.4em;
    }
}

// Custom ul arrow
%custom-ul-arrow {
    @extend %custom-ul;
}

%custom-ul-arrow > li:before {
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f105';
    top: 0.1em;
    left: 0;
    font-size: 0.9em;
}

// Custom ul dot
%custom-ul-dot {
    @extend %custom-ul;
}

%custom-ul-dot > li:before {
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f111';
}

// Custom ul check
%custom-ul-check {
    @extend %custom-ul;
}

%custom-ul-check > li:before {
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f00c';
    top: 0.2em;
    left: -0.2em;
    font-size: 0.8em;
}

// Custom ul dash
%custom-ul-dash {
    @extend %custom-ul;
}

%custom-ul-dash > li {
    padding-left: 0.8em;
}

%custom-ul-dash > li:before {
    content: '-';
    display: inline-block;
    position: absolute;
    top: -0.2em;
    left: 0;
    font-size: 1em;
}

// Ordered list
%custom-ol li {
    margin-bottom: 5px;
}

%custom-ol ol {
    margin-top: 5px;
    padding-left: 1.9rem;
}

%custom-ol ul {
    margin-top: 5px;
}

;@import "sass-embedded-legacy-load-done:2194";