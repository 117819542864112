.main-heading {
    margin-top: rfs-value(40px);
    margin-bottom: rfs-value(40px);

    @include media-breakpoint-up(lg) {
        margin-top: rfs-value(45px);
        margin-bottom: rfs-value(45px);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rfs-value(65px);
        margin-bottom: rfs-value(55px);
    }
}

;@import "sass-embedded-legacy-load-done:2207";