.logo {
    width: 275px;
    margin: rfs-value(10px) 0;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
        width: rfs-value(350px);
    }
}

.scrolled .logo {
    @include media-breakpoint-up(md) {
        width: rfs-value(250px);
        margin: rfs-value(10px) 0;
    }
}

.logo-link {

}

.logo-image {
}

.logo-link svg {
    width: 100%;
    height: auto;
}

.logo-image-wrapper {
    transition: $transition-base;
    opacity: 1;
}

.scrolled .logo-image-wrapper {
    opacity: 0;
}

.scrolled-logo-image-wrapper {
    transition: $transition-base;
    opacity: 0;
}

.scrolled .scrolled-logo-image-wrapper {
    opacity: 1;
}

;@import "sass-embedded-legacy-load-done:2210";